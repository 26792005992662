// Content helpers
// -------------------------

// typography page icon with list
.list-style-icons {
	padding-left: 10px;
	margin-left: 0;
	list-style: none;
	> li svg,
	> li i {
		margin-right: 6px;
	}
}

//Pull the element
.pull-up {
	transition: all 0.25s ease;

	&:hover {
		transform: translateY(-4px) scale(1.02);
		box-shadow: 0px 14px 24px rgba(62, 57, 107, 0.2);
		z-index: 30;
	}
}

// Spinner classes
.spinner {
	display: inline-block;
	animation: spin 1s linear infinite;
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.spinner-reverse {
	display: inline-block;
	animation: spin-reverse 1s linear infinite;
}
@keyframes spin-reverse {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(-360deg);
	}
}

// Bullets used in application pages
.bullet {
	width: 1rem;
	height: 1rem;
	border-radius: 50%;
	display: inline-block;
	&.bullet-xs {
		width: 0.5rem;
		height: 0.5rem;
	}
	&.bullet-sm {
		width: 0.714rem;
		height: 0.714rem;
	}
	&.bullet-lg {
		width: 1.25rem;
		height: 1.25rem;
	}
}

// Section Label - used in pages and apps
.section-label {
	font-size: 0.85rem;
	color: $text-muted;
	text-transform: uppercase;
	letter-spacing: 0.6px;
}

// Used for table cell fit
.cell-fit {
	width: 0.1%;
	white-space: nowrap;
}

// Card match height
.match-height {
	> [class*='col'] {
		display: flex;
		flex-flow: column;

		> .card {
			flex: 1 1 auto;
		}
	}
}
