table.dataTable {
	// table width & collapse border
	width: 100% !important;
	border-collapse: collapse !important;
	&.table-responsive:not(.datatables-ajax) {
		display: block; // For responsive data-table of BS5
	}
	td,
	th {
		padding: $table-cell-padding-y 1.5rem;
		vertical-align: middle;
	}
	thead {
		// Sorting Icons Change
		.sorting,
		.sorting_asc,
		.sorting_desc {
			padding-right: inherit;
			&:before {
				background-image: url(str-replace(
					str-replace($chevron-up, 'currentColor', $table-head-color),
					'#',
					'%23'
				));
				background-repeat: no-repeat;
				background-position: center;
				background-size: 12px;
				color: $body-color;
				width: 14px;
				height: 14px;
				content: '';
				right: 0.3rem;
				top: 0.5rem;
			}
			&:after {
				background-image: url(str-replace(
					str-replace($chevron-down, 'currentColor', $table-head-color),
					'#',
					'%23'
				));
				background-repeat: no-repeat;
				background-position: center;
				background-size: 12px;
				color: $body-color;
				width: 14px;
				height: 14px;
				content: '';
				right: 0.3rem;
				top: 1.3rem;
			}
		}
	}
}

// Export button dropdown UI
div.dataTables_wrapper .dt-button-collection {
	background-color: $dropdown-bg;
	> div[role='menu'] {
		text-align: left;
		box-shadow: 0px 5px 16px -8px;
		min-width: 8rem;
	}
	[class*='buttons-']:focus {
		outline: 0;
	}
}

// Custom responsive style for BS5
// Responsive table area '+' icon position
table.dataTable.dtr-column > tbody > tr > td.control:before,
table.dataTable.dtr-column > tbody > tr > th.control:before {
	line-height: 0.8em;
}

// To scroll within datatable area
@media screen and (max-width: 1399.98px) {
	table.dataTable.table-responsive {
		display: block;
	}
}

// Modal table style
.modal.dtr-bs-modal {
	.modal-body {
		padding: 0;
	}
	.table {
		tr:last-child > td {
			border-bottom: 0;
		}
	}
}

// data table search, labels and
div.dataTables_wrapper {
	// data table search, labels
	div.dataTables_filter,
	div.dataTables_length {
		label {
			margin-top: 1rem;
			margin-bottom: 0.5rem;
		}
		// for datatable length select
		select {
			background-position: calc(100% - 3px) 11px, calc(100% - 20px) 13px, 100% 0;
			// padding: 0 0.8rem;
			width: 5rem;
			margin: 0 0.5rem;
		}
	}
	div.dataTables_filter input {
		margin-left: 0.75rem;
	}

	// pagination
	div.dataTables_paginate {
		ul.pagination {
			margin-top: 1rem;
			margin-bottom: 1.5rem;
		}
	}

	// info text color
	.dataTables_info {
		padding-top: 1.25rem !important;
		color: $text-muted;
	}
}

// row grouping
tr.group {
	// row grouping color
	background-color: $table-row-group-bg;
}

@media only screen and (max-width: 992px) {
	table.dataTable {
		&.table-responsive.datatables-ajax {
			display: block; // For responsive data-table of BS5
		}
	}
}
// pagination in center
@media only screen and (max-width: 768px) {
	div.dataTables_wrapper {
		div.dataTables_paginate {
			ul.pagination {
				justify-content: center;
			}
		}
	}

	div.dataTables_wrapper div.dataTables_info {
		white-space: inherit;
	}
	// small screen button spacing in bottom
	div.dt-buttons {
		justify-content: center;
		margin-top: 1rem;
		button {
			margin-bottom: 0.5rem;
		}
	}
}
@include media-breakpoint-down(sm) {
	.dtr-bs-modal {
		.modal-body {
			padding-left: 0;
			padding-right: 0;
			overflow-x: scroll;
		}
		.table.dtr-details {
			margin: 0 auto 1rem;
			td {
				padding-left: 0;
				padding-right: 0;
			}
			tr td:first-child {
				padding-left: 0.75rem;
			}
		}
	}
	div.dataTables_wrapper {
		overflow: scroll;
	}
}
