/*
NOTE:
------
PLACE SCSS HERE*/
@import '../../@core/scss/base/bootstrap-extended/include';

.product-gallery-swiper {
	margin-bottom: 16px;
}
.product-gallery-img-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $gray-50;
}
.product-gallery-img {
	max-height: 400px;
}
.no-margin {
	margin: 0.5rem 1.5rem 0 0;
}
.product-card-img {
	height: 200px;
}
.order-row-item {
	text-overflow: ellipsis;
	overflow: hidden;
	max-height: 1.8em;
	line-height: 1.8em;
	word-wrap: break-word;
}
